import React, { useState, useEffect, useRef } from 'react'
import { ReplyIcon, XCircleIcon } from '@heroicons/react/outline';
import { useLocation } from 'react-router-dom'
import JSConfetti from 'js-confetti';

export default function Step4({ prevStep, nextStep, setSuccess, updateFieldValue, additional, dataArray, setDataArray, initialState, agree, did, source }) {

    const [agreeErr, setAgreeErr] = useState(false);
    const jsConfetti = new JSConfetti()

    const topRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to top for this step
        topRef.current?.scrollIntoView({ behavior: 'smooth', block:'center' });
    });

    const notifyWebhook = async (url, body) => {
        // console.log("POST", url, body)
        return fetch(url, {
            mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    const date = new Date();

    const pst = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    const handleSubmit = () => {
        if (agree) {
            // if no errors...
            try {
                setAgreeErr(false)
                nextStep(2)
                //add timestamp
                dataArray['time_stamp'] = pst + " PST"
                dataArray['url'] = window.location.href
                dataArray['did'] = did
                // dataArray['source'] = source
                // console.log(dataArray)
                //use zapier
                notifyWebhook(source.includes("Craftwise") ? "https://hooks.zapier.com/hooks/catch/13095819/bqknqwg/" : "https://hooks.zapier.com/hooks/catch/13095819/bqkvgsp/", dataArray)
                jsConfetti.addConfetti()
                setDataArray(initialState)
                setSuccess(true)
            } catch (error) {
                console.error('error', error);
            }
        } else {
            setAgreeErr(true)
        }
    }

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if(agree){
    //         // if no errors...
    //         try {
    //             setAgreeErr(false)
    //             console.log("POST + add jornaya")
    //             nextStep(4)
    //             //post to a db
    //             const { data: taxLeadInsert, error: taxLeadInsert_error, status: taxLeadInsert_status } = await supabase
    //             .from("tax_leads")
    //             .insert([
    //                 {
    //                     first_name: dataArray.first_name,
    //                     last_name: dataArray.last_name,
    //                     email_address: dataArray.email_address,
    //                     phone_number: dataArray.phone_number,
    //                     state: dataArray.state,
    //                     zip_code: dataArray.zip_code,
    //                     total_debt: dataArray.total_debt,
    //                     owe_over_5k: dataArray.owe_over_5k,
    //                     additional_info: dataArray.additional,
    //                     other_help: dataArray.other,
    //                     consent_agree: dataArray.agree,
    //                     did: dataArray.did,
    //                     url: location.href,
    //                     source: source
    //                 }
    //             ])

    //         if (taxLeadInsert_error && taxLeadInsert_status !== 406) {
    //             throw taxLeadInsert_error;
    //         }

    //         //works correctly
    //         if (taxLeadInsert) {
    //             console.log('Successfully submitted information.')
    //         }


    //         } catch (error) {
    //             console.error('error', error);
    //         } finally {  
    //         }
    //     } else {
    //         setAgreeErr(true)
    //     }
    // }


    return (
        <div ref={topRef} id="step2">
            <div className="col-span-6 sm:col-span-4 mb-6">
                <label htmlFor={`additional`} className="text-center mb-5 block text-md md:text-xl font-medium text-gray-800">
                    Any additional information we should know prior to the consultation?
                </label>
                <p className='text-xs italic'>Optional (100 character limit)</p>
                <div className="mt-1">
                    <textarea
                        id="additional"
                        name="additional"
                        rows={1}
                        maxLength={100}
                        className="mt-1 block w-full rounded-sm py-2 px-3 border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(event) => updateFieldValue('additional', event.target.value)}
                        value={additional}
                    />
                </div>
            </div>

            <div className="text-left col-span-6 sm:col-span-4 mb-6">

                <input className="mr-2 h-4 w-4"
                    type="checkbox"
                    id={`agreeCheckbox`}
                    checked={agree}
                    onChange={(event) => updateFieldValue("agree", event.target.checked)}
                />

                <label className="text-xs italic text-gray-500" htmlFor="agreeCheckbox">
                    I agree to share my information with the following partner representatives,
                    and for them to contact me at the phone number and email provided above (including through automated means;
                    e.g. auto-dialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or
                    email, even if your telephone number is currently listed on any state, federal or corporate "Do Not Call" list.
                    This is not a condition of purchase.  Standard message and data rates may apply. For more information, visit
                    the <a href="/privacy-policy" className='underline'>Privacy Policy</a> of USATaxDebt.com.
                </label>
                {agreeErr &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                        <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please agree to the terms and conditions above to proceed.
                    </p>
                }
            </div>

            <div className="flex flex-col my-10 justify-center">
                <button
                    onClick={(event) => handleSubmit(event)}
                    id="stepFormSubmitButton"
                    className="my-2 inline-flex items-center justify-center px-5 py-3 shadow-sm text-base uppercase font-medium text-white bg-gray-800 hover:bg-cyan-900"
                >
                    Submit
                </button>
            
                <button
                    //add onclick to save all pattern yarn info and post
                    onClick={() => {prevStep(2); updateFieldValue("phone_number", "")}}
                    type="button"
                    id="stepFormPrevButton4"
                    className="
                        inline-flex 
                        items-center 
                        justify-center  
                        mr-3
                        mt-5
                        text-sm 
                        w-full
                        order-last
                        font-medium 
                        text-gray-900
                        hover:text-cyan-800
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-offset-2 
                        focus:ring-cyan-500"
                >
                    <ReplyIcon className='h-3 w-3 mr-2' /> Back
                </button>
            </div>
        </div>
    );
}




























// import React, { useState } from 'react'
// import { XCircleIcon } from '@heroicons/react/outline';

// export default function Step5({prevStep, nextStep, updateFieldValue, ...FormFields}) {

//     const [disabledButton, setDisabledButton] = useState(true)

//     const [loadingButton, setLoadingButton] = useState(false)

//     const errorObj = Object.keys(FormFields).reduce((prev,curr)=>(prev[curr]=false, prev),{})

//     //create an object of key value pair set to false each with a key === names of all basic form field keys
//     const [errors, setErrors] = useState({...errorObj})

//     const validFormFields = () => {
//         let updateErrorObj = {...errors};
//         Object.entries(FormFields).some(([key, value]) => {
//             if (key === "additional"){
//                 updateErrorObj[key] = false
//             } else if (!value) {
//                 updateErrorObj[key] = true;
//                 console.log("error", key, value)
//             } else {
//                 updateErrorObj[key] = false
//             }
//         })
//         setErrors(updateErrorObj)
//         return (Object.values(updateErrorObj).includes(true)) ? false : true
//     }

//     const handleNextStep = () => {
//         if (validFormFields()) {
//             setDisabledButton(false)
//             // if no errors...
//             try {
//                 setLoadingButton(true)
//                 nextStep(5)
//                 //post to a db
//             } catch (error) {
//                 console.error('error', error);
//             } finally {
//                 setLoadingButton(false);
//             }
//         }
//     }

//   return (
//     <div className='text-center md:text-left col-span-6 sm:col-span-4 p-4'>
//         {Object.entries(FormFields).map(([key, value]) =>
//         <div key={key}>
//             {key === "agree" ?
//             <div className="flex items-start">
//                 <div className="flex items-center h-5">
//                 <input
//                     id={`${value}-${key}`}
//                     name={key}
//                     type="checkbox"
//                     className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
//                 />
//                 </div>
//                 <div className="ml-3 text-sm">
//                     <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-700 capitalize">
//                         {key} to Terms &amp; Conditions
//                     </label>
//                 <p className="text-gray-500">
//                     I agree to share my information with the following
//                     <u>partner representatives</u>, and for them to contact me at the phone number
//                     and email provided above (including through automated means; e.g. auto-dialing,
//                     text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS)
//                     and/or email, even if your telephone number is currently listed on any state,
//                     federal or corporate "Do Not Call" list.  This is not a condition of purchase.
//                     Standard message and data rates may apply. For more information, visit the
//                     <u>Terms &amp; Conditions</u> and <u>Privacy Policy</u> of USATaxDebt.com.</p>
//                 </div>
//             </div>
//             :
//             <div className="col-span-6 sm:col-span-4 mb-6">
//                 <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-700 capitalize">
//                     {key}
//                 </label>
//                 <input
//                     type="text"
//                     name={key}
//                     maxLength="50"
//                     id={`${value}-${key}`}
//                     defaultValue={`${value}`}
//                     onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
//                     className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm text-gray-700 border-gray-300 rounded-md"
//                 />
//                 {errors[key] &&
//                     <p className="mt-2 mb-0 text-xs text-red-500 flex">
//                     <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid {key}.
//                     </p>
//                 }
//             </div>}
//         </div>
//         )}

// <div className="shadow px-4 py-3 bg-gray-50 text-right sm:px-6 rounded-b-lg">
//         <button
//             //add onclick to save all pattern yarn info and post
//             onClick={() => prevStep(1)}
//             className="
//             inline-flex
//             items-center
//             justify-center
//             rounded-md
//             py-2
//             px-4
//             mr-3
//             shadow-sm
//             text-sm
//             font-medium
//             rounded-md
//             text-gray-800
//             border
//             border-gray-800
//             hover:border-red-500
//             hover:bg-red-500
//             hover:text-white
//             focus:outline-none
//             focus:ring-2
//             focus:ring-offset-2
//             focus:ring-blue-500"
//         >
//             Back
//         </button>
//             <button
//             onClick={(event)=> handleNextStep(event)}
//             disabled={disabledButton}
//             className={`
//             inline-flex
//             items-center
//             justify-center
//             rounded-md
//             py-2
//             px-4
//             shadow-sm
//             text-sm
//             font-medium
//             rounded-md
//             text-white
//             bg-gray-800
//             hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500
//             focus:outline-none
//             focus:ring-2
//             focus:ring-offset-2
//             focus:ring-blue-500
//             `}
//           >
//               Next
//             </button>
//         </div>
//     </div>
//   );
// }
