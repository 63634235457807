import React from 'react';
import { XCircleIcon, ReplyIcon } from '@heroicons/react/outline';
// import InputMask from 'comigo-tech-react-input-mask';
import Input from 'react-phone-number-input/input'

export default function Step1({ prevStep, nextStep, updateFieldValue, owe_over_5k, ...FormFields }) {

    const errorObj = Object.keys(FormFields).reduce((prev, curr) => (prev[curr] = false, prev), {})

    //create an object of key value pair set to false each with a key === names of all basic form field keys
    const [errors, setErrors] = React.useState({ ...errorObj })

    const [phone, setPhone] = React.useState(FormFields.phone_number);

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    const validFormFields = () => {
        let updateErrorObj = { ...errors };
        Object.entries(FormFields).some(([key, value]) => {
            if (key === "email_address") {
                if (!validEmail.test(value) || !value || value.length === 0) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }
            } else if (key === 'phone_number') {
                if (!phone || phone.replace("+", '').length !== 11) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }

            } else if (!value) {
                updateErrorObj[key] = true;
                // console.log("error", key, value)
            } else {
                updateErrorObj[key] = false
            }
        })
        setErrors(updateErrorObj)
        return (Object.values(updateErrorObj).includes(true)) ? false : true
    }


    const handleNextStep = () => {

        if (validFormFields()) {
            // if no errors...
            try {
                nextStep(1)
                updateFieldValue("phone_number", phone.replace("+", ''))
                //post to a db
            } catch (error) {
                console.error('error', error);
            } finally {
            }
        }
    }



    return (
        <div id="step1">
            <div className='grid md:grid-cols-12 gap-4'>
            {Object.entries(FormFields).map(([key, value]) =>
            (key === 'phone_number' ?
                <div key={key} className="col-span-12 mb-2">
                    <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-800 capitalize">
                        {key.replace(/_/g, ' ')}
                    </label>
                    <Input
                        defaultCountry="US"
                        country='US'
                        value={phone}
                        onChange={setPhone}
                        className="mt-1 block w-full rounded-sm py-2 px-3 border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                    />
                    {FormFields.phone_number}
                    {errors[key] &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex">
                            <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid phone number.
                        </p>
                    }
                </div>
                :
                (key === 'email_address' ?
                    <div key={key} className="col-span-12 mb-2">
                        <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-800 capitalize">
                            {key.replace(/_/g, ' ')}
                        </label>
                        <input
                            type="text"
                            name={key}
                            maxLength="50"
                            id={`${value}-${key}`}
                            defaultValue={`${value}`}
                            onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                            className="mt-1 block w-full rounded-sm py-2 px-3 border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                        />
                        {errors[key] &&
                            <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid {key.replace(/_/g, ' ')}.
                            </p>
                        }
                    </div>
                :
                <div key={key} className="md:col-span-6 col-span-12 mb-2">
                    <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-800 capitalize">
                        {key.replace(/_/g, ' ')}
                    </label>
                    <input
                        type="text"
                        name={key}
                        maxLength="50"
                        id={`${value}-${key}`}
                        defaultValue={`${value}`}
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className="mt-1 block w-full py-2 px-3 rounded-sm border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                    />
                    {errors[key] &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex">
                            <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid {key.replace(/_/g, ' ')}.
                        </p>
                    }
                </div>)
            )
            )}
            </div>

            <div className="flex flex-col mt-10">

                <button
                    type="button"
                    onClick={(event) => handleNextStep(event)}
                    id="stepFormButtonNextButton1"
                    className="my-2 inline-flex items-center justify-center px-5 py-3 shadow-sm text-base font-medium text-white bg-gray-800 hover:bg-cyan-900"
                >
                   Free Consultation
                </button>

            </div>
        </div>
    )
}
