import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Success from './Success';
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;

export default function StepForm(props) {
    const [currentStep, setCurrentStep] = useState(1)

    const prevStep = (step) => {
        setCurrentStep(step - 1)
    }

    const nextStep = (step) => {
        setCurrentStep(step + 1)
    }
  

    const steps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={props.updateFieldValue}
                        first_name={props.dataArray.first_name}
                        last_name={props.dataArray.last_name}
                        phone_number={props.dataArray.phone_number}
                        email_address={props.dataArray.email_address}
                        owe_over_5k={props.dataArray.owe_over_5k}
                    />
                )
            // case 2:
            //     return (
            //         <Step2
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={props.updateFieldValue}
            //             total_debt={props.dataArray.total_debt}
            //         />
            //     )
            // case 3:
            //     return (
            //         <Step3
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={props.updateFieldValue}
            //             state={props.dataArray.state}
            //             zip_code={props.dataArray.zip_code}
            //         />
            //     )
            case 2:
                return (
                    <Step4
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={props.updateFieldValue}
                        additional={props.dataArray.additional}
                        agree={props.dataArray.agree}
                        dataArray={props.dataArray}
                        source={props.source}
                        did={props.did}
                        initialState={props.initialState} 
                        setDataArray={props.setDataArray}
                        setSuccess={props.setSuccess}
                    />
                )
            case 3:
                return (
                    <Success did={props.did} />
                )
            // never forget the default case, otherwise VS code would be mad!
            default:
            // do nothing
        }
    }

    return (
        <div className='mx-2'>
            <h3 className="md:px-20 py-2 md:py-5 px-4 text-center text-xl md:text-3xl mb-0 leading-8 font-extrabold tracking-tight font-serif text-gray-900 sm:text-4xl">
              Back Tax Help - Free Consultation
            </h3>
            <p className='text-center text-sm md:text-xl font-normal text-gray-800 mx-auto max-w-2xl mb-1 md:mb-10'>Get the help you deserve, starting with a free back tax consultation.</p>
            <p className='text-center px-5 md:px-10 text-gray-500 text-sm md:text-base mb-1 md:mb-10 mx-auto max-w-2xl'>During your back tax consultation, a tax professional will answer all of your tax-related concerns and determine if you qualify for a tax forgiveness program.</p>
            <div className='relative md:px-10 p-4 max-w-3xl' id="consultationForm">
                {/* {currentStep === 2 && <FadeInRightDiv className='mb-8 w-full text-center text-sm italic text-green-600 font-semibold'>Looks good! Keep going!</FadeInRightDiv>}
                {currentStep === 3 && <SlideInUpDiv className='mb-8 w-full text-center text-sm italic text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
                {currentStep === 4 && <FadeInRightDiv className='mb-8 w-full text-center text-sm italic text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>} */}
                <div className='mt-2'>
                {steps()}
                </div>
            </div>
            <p className='text-center'>See if you qualify for a free debt settlement consultation today. <i>No cost, no obligation.</i></p>
        </div>
    )
}
