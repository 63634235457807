import React, { useState } from 'react'
import QuizStep1 from './QuizStep1';
import QuizStep2 from './QuizStep2';
import QuizStep3 from './QuizStep3';
import QuizStep4 from './QuizStep4';
import QuizStep5 from './QuizStep5';
import Success from './Success';
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';
import { propTypes } from 'comigo-tech-react-input-mask/lib/react-input-mask.development';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;


export default function QuizForm(props) {
  const [currentStep, setCurrentStep] = useState(1)

  const prevStep = (step) => {
    setCurrentStep(step - 1)
  }

  const nextStep = (step) => {
    setCurrentStep(step + 1)
  }


  const steps = () => {
    switch (currentStep) {
      case 1:
        return (
          <QuizStep1
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            owe_over_5k={props.dataArray.owe_over_5k}
            other={props.dataArray.other}
          />
        )
      case 2:
        return (
          <QuizStep2
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            total_debt={props.dataArray.total_debt}
          />
        )
      // case 3:
      //   return (
      //     <QuizStep3
      //       prevStep={prevStep}
      //       nextStep={nextStep}
      //       owe_over_5k={props.dataArray.owe_over_5k}
      //       updateFieldValue={props.updateFieldValue}
      //       state={props.dataArray.state}
      //       zip_code={props.dataArray.zip_code}
      //     />
      //   )
      case 3:
        return (
          <QuizStep4
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            first_name={props.dataArray.first_name}
            last_name={props.dataArray.last_name}
            phone_number={props.dataArray.phone_number}
            email_address={props.dataArray.email_address}
            owe_over_5k={props.dataArray.owe_over_5k}
          />
        )
      case 4:
        return (
          <QuizStep5
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            additional={props.dataArray.additional}
            agree={props.dataArray.agree}
            first_name={props.dataArray.first_name}
            last_name={props.dataArray.last_name}
            phone_number={props.dataArray.phone_number}
            email_address={props.dataArray.email_address}
            total_debt={props.dataArray.total_debt}
            state={props.dataArray.state}
            zip_code={props.dataArray.zip_code}
            dataArray={props.dataArray}
            source={props.source}
            did={props.did}
            initialState={props.initialState} 
            setDataArray={props.setDataArray}
            setSuccess={props.setSuccess}
          />
        )
      case 5:
        return (
          <Success did={props.did} />
        )
      // never forget the default case, otherwise VS code would be mad!
      default:
      // do nothing
    }
  }

  return (
    <div className='w-full md:w-3/4 bg-white mx-auto px-5 pt-10 pb-5 md:py-10 text-gray-500'>
      <p className='text-center text-gray-500 text-base uppercase font-extrabold tracking-widest'>Back Tax Help - Quiz</p>
      <h3 className="text-center md:text-3xl text-xl md:my-10 my-5 leading-8 font-extrabold tracking-tight font-serif text-gray-900">
        Find Out If You <br />Qualify For Back Tax Help
      </h3>
      <p className='md:text-md text-center md:px-10 mx-auto text-gray-700 text-base italic mb-6 md:mb-10'>See if you qualify for a back tax consultation. <br />No cost, no obligation.</p>
      <div className='rounded-md mx-auto my-5 max-w-md' id="quizForm">
        {/* {currentStep === 2 && <SlideInUpDiv className='text-center text-sm italic text-green-600 font-semibold'>Looks good! Keep going!</SlideInUpDiv>}
        {currentStep === 3 && <FadeInRightDiv className='text-center text-sm italic text-green-600 font-semibold'>Got it! You&apos;re more than halfway done!</FadeInRightDiv>}
        {currentStep === 4 && <SlideInUpDiv className='text-center text-sm italic text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
        {currentStep === 5 && <FadeInRightDiv className='text-center text-sm italic text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>} */}
        {steps()}
      </div>
    </div>
  )

}
