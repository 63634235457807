import { PhoneOutgoingIcon, CheckIcon, CheckCircleIcon, PhoneIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react'
import Navbar from './Navbar';
import QuizForm from './QuizForm';
import StepForm from './StepForm';
import styled, { keyframes } from 'styled-components'
import { fadeInRight, fadeInUp, fadeIn } from 'react-animations';
import AccordionItem from './Accordion/AccordionItem'
import BackButtonModal from './BackButtonModal';
import ScrollModal from './ScrollModal'

const FadeRight = styled.div`animation: 1s ${keyframes`${fadeInRight}`};`
const FadeInUp = styled.div`animation: 1s ${keyframes`${fadeInUp}`};`
const FadeIn = styled.div`animation: 2s ${keyframes`${fadeIn}`}`

export default function LandingPage(props) {

    const initialState = {
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        state: "",
        zip_code: "",
        total_debt: "",
        owe_over_5k: "",
        additional: "",
        other: "",
        agree: true,
    }

    const [dataArray, setDataArray] = useState(initialState)

    const [back, setBack] = useState(false)
    const [backOnce, setBackOnce] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [success, setSuccess] = useState(false)

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
    }

    const today = new Date()
    const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
    const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);

    const online = () => {
        //if weekend
        if (isWeekend) {
            return null
        } else {
            //if its 6am-6pm
            if (isOpen) {
                return (
                    <div className='flex items-center justify-center'>
                        <div className='relative'>
                            <span className="animate-ping absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                            <span className="absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
                        </div>
                        <div className='ml-5 mt-2 text-base'>Active</div>
                    </div>
                )
            } else {
                return null
            }
        }
    }

    //for the back button/leaving
    // useEffect(() => {
    //     var mouseY = 0;
    //     var topValue = 0;
    //     if (backOnce === true) {
    //         setBack(false)
    //         const timer = setTimeout(() => {
    //             setScroll(false)
    //             setBackOnce(false)
    //         }, 60000); //60 seconds
    //         return () => clearTimeout(timer);
    //     } else {
    //         window.addEventListener("mouseout", function (e) {
    //             mouseY = e.clientY;
    //             if (mouseY < topValue) {
    //                 setBack(true)
    //                 setScroll(false)
    //             }
    //         },
    //             false);
    //     }
    // }, [backOnce])

    // for scroll
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setScroll(true)
    //         setBack(false)
    //     }, 30000); //30 seconds
    //     return () => clearTimeout(timer);
    // }, []);


    const accordionData = [
        {
            id: 1,
            question: "Does the IRS forgive back taxes?",
            answer: "It depends on your specific tax debt situation. While it is rare for the IRS to fully forgive tax debt, acceptance into a forgiveness plan helps you avoid penalties associated with owing tax debt. And more often than not, plans can substantially lessen the amount owed to a fraction of the initial debt."
        },
        {
            id: 2,
            question: "Can I negotiate with the IRS?",
            answer: "Yes! You can absolutely negotiate with the IRS, however it can be extremely daunting and time consuming if you don't have the correct resources. But don't worry! Our team of national tax experts will help you navigate the tricky inner-workings of back taxes so you can walk away a satisfied customer."
        },
        {
            id: 3,
            question: "How do I qualify to back tax help?",
            answer: "If you owe over $5,000 in back taxes you qualify for a free consultation on us! Our licensed tax partners meticulously assess your individual debt situation and devise a plan that works best for you. They work tirelessly to help you resolve your IRS tax debt in the most efficient way possible!"
        },
    ]


    return (
        <div>
            {
                !isWeekend && isOpen && back && !success &&
                <div>
                    <BackButtonModal did={props.did} backOnce={backOnce} setBackOnce={setBackOnce} open={back} setOpen={setBack} />
                </div>
            }
            {
                !isWeekend && isOpen && scroll && !success &&
                <div>
                    <ScrollModal did={props.did} open={scroll} setOpen={setScroll} />
                </div>
            }

            {/* CTA start */}
            <div className='banner'>
                {/* Navbar start */}
                <Navbar did={props.did} />
                {/* Navbar end */}
                <div className='relative'>
                    <div className='py-2 flex flex-col md:flex-row justify-center items-center text-white' style={{ backgroundColor: "rgb(0, 36, 74)" }}>
                        <span className='text-sm italic'>in partnership with</span>
                        <div className='flex items-center font-bold text-xl wendy uppercase tracking-widest'>
                            <img
                                className="block mt-2 mx-2 h-8 w-auto not-italic"
                                src="/ovation-logo.png"
                                alt="Ovation"
                            /> Ovation Tax Group
                        </div>
                    </div>
                    <div className='max-w-7xl mx-auto lg:flex items-center justify-center'>
                        <div className='mx-auto'>
                            <div className='text-center my-0 px-5 py-10 md:p-10'>
                                <h1 className="tracking-normal font-extrabold text-white text-5xl">
                                    <span className="block text-3xl md:text-4xl xl:inline">Remove Your</span>{' '}
                                    <span className="block text-3xl md:text-4xl xl:inline">Tax Debt in Just</span>{' '}
                                    <span className="block text-3xl md:text-4xl xl:inline">24 Hours</span>{' '}
                                </h1>
                                <p className="mt-3 text-sm md:text-md font-semibold text-white sm:mt-5 max-w-md mx-auto md:mt-5">
                                    Ignoring the issue won't help pay your taxes. Let our experts with <span className='italic'>20+ years of experience</span> start a tax resolution plan for you in as little as 1 day.
                                </p>

                                <div className='flex items-center justify-center'>
                                    <a href="#consultation" id="firstButtonLearnMore" className="lg:mt-10 hover:translate-y-0.5 text-xl text-gray-800 bg-white rounded-sm my-4 hover:bg-gray-200 p-4 md:px-10 md:py-5 font-extrabold">
                                        Start debt forgiveness today
                                    </a>
                                </div>
                                {props.did &&
                                    <div className="flex items-center justify-center text-xl md:text-base">

                                        <a
                                            href={`tel:${props.did}`}
                                            id="buttonPhoneCTA"
                                            className="flex items-center p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        >
                                            <p className='text-white mr-1'>Call Now:</p>{props.did}
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <FadeRight className='hidden md:block basis-4/6 mx-auto py-10' id="quiz">
                            <QuizForm
                                dataArray={dataArray}
                                setDataArray={setDataArray}
                                initialState={initialState}
                                updateFieldValue={updateFieldValue}
                                source={props.source}
                                did={props.did}
                                setSuccess={setSuccess}
                            />
                        </FadeRight> */}
                        {/* <FadeInUp className='block md:hidden basis-4/6 mx-auto' id="quiz">
                            <QuizForm
                                dataArray={dataArray}
                                setDataArray={setDataArray}
                                initialState={initialState}
                                updateFieldValue={updateFieldValue}
                                source={props.source}
                                did={props.did}
                                setSuccess={setSuccess}
                            />
                        </FadeInUp> */}
                    </div>
                </div>
            </div>
            {/* CTA end */}
            {/* Banner end */}


            {/* Arrow banner start */}
            {/* <div>
                <div className='-mt-5 md:mt-0 mb-5 md:mb-10 bg-gray-200 md:p-5 px-5 py-5'>
                    <div className='max-w-7xl mx-auto'>
                        <div className='uppercase text-gray-800 text-base md:text-xl tracking-wide font-thin font-md flex flex-wrap items-center justify-center'>
                            <span className='font-serif text-center md:text-left'> Looking to get started ASAP?</span>
                            <a className='underline hover:text-teal-700 text-gray-900 mx-2 group flex tracking-widest font-extrabold items-center'
                                href="#consultation"
                                id="secondButtonConsultation"
                            >
                                Free Back Tax Consultation</a>
                        </div>

                    </div>
                </div>
            </div> */}
            {/* Arrow banner end */}


            {/* Reviews start */}
           <div className='bg-gray-100'>
           <div className='max-w-5xl mx-auto grid md:grid-cols-3'>
                <div className='p-5 rounded-sm'>
                    <p className='font-bold text-base md:text-lg'>Angel R.</p>
                    <p className='text-sm my-2'>"I want to thank you for everything you did to <b>resolve my mother’s estate
                        tax</b> matter with IRS.
                        You produced the <b>best possible result</b>. The beneficiaries are most grateful
                        and happy to recommend you to others."</p>
                    <div className='mt-2 flex items-center justify-start'>
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <span className='text-gray-700 text-xs italic ml-1'>Google Reviews</span>
                    </div>
                </div>
                <div className='p-5 rounded-sm'>
                    <p className='font-bold text-base md:text-lg'>Nick B.</p>
                    <p className='text-sm my-2'>"I would definitely give Ovation Tax Group <b>five stars</b> I went there
                        to file my taxes and got back more than I could even
                        expect I will be going there every year from now on
                        yes I would <b>recommend my family</b>."</p>
                    <div className='mt-2 flex items-center justify-start'>
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <span className='text-gray-700 text-xs italic ml-1'>Google Reviews</span>
                    </div>
                </div>
                <div className='p-5 rounded-sm'>
                    <p className='font-bold text-base md:text-lg'>Antonio M.</p>
                    <p className='text-sm my-2'>"I've been coming to Ovation Tax Group for a very long time
                        and every time I was satisfied I would <b> recommend to my family
                            and friends</b> they can help you from doing your
                        taxes to help with the IRS <b>five stars</b> all the way."</p>
                    <div className='mt-2 flex items-center justify-start'>
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <StarIcon className='h-4 w-4 text-yellow-300' />
                        <span className='text-gray-700 text-xs italic ml-1'>Google Reviews</span>
                    </div>
                </div>
            </div>
           </div>
            {/* Reviews end */}



            {/* About us  start */}
            <div className='grid lg:grid-cols-12 gap-4 max-w-7xl mx-auto' id="about">
                <div className='z-30 relative md:col-span-5'>
                    <div className='absolute bottom-0'>
                        <img
                            src="/tax_partners.webp"
                            className='hidden lg:block h-96 w-auto object-contain object-bottom'
                            alt="our national partners"
                        />
                    </div>
                </div>

                <div className='lg:col-span-7 px-2 md:px-10'>
                    <div className='md:p-10 text-center md:text-left'>
                        <p className="text-center md:text-left mt-5 md:mt-10 text-base md:text-lg leading-8 font-extrabold uppercase tracking-widest text-teal-600">National Tax Debt Partners</p>
                        <p className="text-center md:text-left mt-5  mb-10 leading-8 font-extrabold tracking-tight text-gray-900 text-2xl md:text-4xl font-serif">Up to date on all things tax</p>
                        <p className="mt-3 max-w-2xl text-left text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">Our national specialists keep up to date on all state tax law changes, relevant tax codes,  practices, procedures, regulations, and federal tax rules. High-quality and well-trained staff members maintain appropriate licensure and specialization towards various tax challenges. Regardless of your tax issue, you won’t find a more experienced and efficient team of professionals.</p>
                    </div>
                </div>
            </div>
            {/* About us  end */}


            {/* Checklist start */}
            <div className='relative bottom-0'>
                <img
                    src="/tax_partners.webp"
                    className='block lg:hidden h-auto w-auto'
                    alt="our national tax partners"
                />
            </div>
            <div className='relative md:py-20 md:px-10 py-10 px-2 md:mt-0' style={{ backgroundColor: "rgb(0, 36, 74)" }}>
                <div className='max-w-3xl mx-auto'>
                    <p className="text-2xl mb-10 md:leading-10 font-extrabold tracking-widest uppercase text-gray-100 sm:text-3xl">How do I <span className='text-teal-400 font-serif'>qualify</span> for back tax help?</p>
                    <p className="mt-3 text-base  mb-10 text-gray-100 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 uppercase tracking-widest md:text-xl lg:mx-0">Check all that apply.</p>
                    <div className='w-full bg-white p-2 md:px-5 md:py-10 text-gray-500'>

                        <div className="mb-4 text-lg sm:text-xl md:text-3xl flex-inline items-center">
                            <input className="mr-2 w-4 h-4 md:h-6 md:w-6 border-none"
                                type="checkbox"
                            />
                            <label><b className='text-gray-700 font-serif'>Owe at least $5,000 or more</b> in back taxes</label>
                        </div>

                        <div className="mb-4 text-lg sm:text-xl md:text-3xl flex-inline items-center">
                            <input className="mr-2 w-4 h-4 md:h-6 md:w-6 border-none"
                                type="checkbox"
                            />
                            <label>Have <b className='text-gray-700 font-serif'>unfiled taxes</b></label>
                        </div>

                        <div className="mb-4 text-lg sm:text-xl md:text-3xl flex-inline items-center">
                            <input className="mr-2 w-4 h-4 md:h-6 md:w-6 border-none"
                                type="checkbox"

                            />
                            <label>Looking for <b className='text-gray-700 font-serif'>step-by-step</b> instructions from a tax professional</label>
                        </div>

                    </div>

                    <p className="text-center mt-10 text-base font-normal text-gray-100 sm:text-lg sm:mx-auto md:text-xl lg:mx-0">
                        If you checked <i>any</i> of the boxes, call the number below for immediate back tax help.
                    </p>
                    <p className='my-5 text-center text-sm md:text-base font-normal text-white uppercase'>No cost, no obligation</p>
                    <a href={`tel:${props.did}`}
                        id="thirdButtonPhone"
                        className="group flex items-center mx-auto justify-center w-full md:w-fit rounded-full drop-shadow-md hover:drop-shadow-2xl p-4 md:px-10 md:py-5 bg-gradient-to-l text-white hover:bg-gradient-to-r from-teal-500 to-sky-500 font-extrabold text-2xl uppercase tracking-widest">
                        <PhoneOutgoingIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 h-8 w-8 mr-2' />
                        {props.did}
                    </a>
                </div>

            </div>
            {/* Checklist end */}


            {/* Services start */}
            <div id="services">
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div className='my-auto text-xl md:py-20 py-10 md:px-10 px-2 max-w-3xl lg:mx-w-2xl md:mx-auto lg:ml-auto'>
                        <p className='text-left font-bold text-cyan-800 uppercase tracking-widest font-serif'>How to</p>
                        <h1 className='text-left uppercase tracking-widest text-gray-800 text-2xl md:text-4xl font-black mb-10'>reduce the total debt amount owed</h1>
                        <p className='text-left text-md tracking-normal max-w-2xl text-gray-700 mb-10 md:mb-16'>Debt settlement allows the creditor and the debtor <i>(a.k.a. you)</i> to negotiate the debt amount to a lesser, more reasonable payment amount.</p>
                        <div className='mb-2 font-semibold text-gray-900 uppercase tracking-widest'>Offer in compromise</div>
                        <p className='text-gray-500 mb-10 text-base'>A negotiation with the IRS to settle your tax debt for less than what is currently owed.</p>
                        <div className='mb-2 font-semibold text-gray-900 uppercase tracking-widest'>Installment Plan</div>
                        <p className='text-gray-500 mb-10 text-base'>An agreement between you and the IRS that allows you to pay off your tax debt in series of regular installments.</p>
                        <div className='mb-2 font-semibold text-gray-900 uppercase tracking-widest'>Penalty Abatement</div>
                        <p className='text-gray-500 mb-10 md:mb-16 text-base'>If you have a valid reason for failing to pay your taxes on time, you may qualify for tax debt forgiveness.</p>
                        <p className='text-center text-base md:text-lg mt-0 font-semibold text-gray-700'>See if you qualify for instant back tax help!</p>
                        <p className='text-center text-sm md:text-base font-normal text-gray-500'>It only take minutes to see if you qualify for back tax help.</p>
                        <p className='my-5 text-center text-sm md:text-base font-normal text-gray-500 uppercase'>FREE Consultation</p>
                        <a href={`tel:${props.did}`}
                            id="fourthButtonConsultation"
                            className="flex items-center mx-auto justify-center mt-5 w-full md:w-fit rounded-sm drop-shadow-md hover:drop-shadow-2xl p-4 md:px-10 md:py-5 bg-sky-700 text-white hover:bg-gradient-to-r hover:from-teal-500 hover:to-sky-500 font-extrabold text-2xl uppercase tracking-widest">
                            {props.did}
                        </a>
                        <img
                            className="mt-5 mx-auto h-10 w-auto not-italic"
                            src="/ovation_logo_dark.png"
                            alt="Ovation"
                        />

                    </div>
                    <div className='banner3'>

                    </div>
                </div>
            </div>
            {/* Services end */}


            {/* FAQ start */}
            <div className='text-white p-2' style={{ backgroundColor: "rgb(0, 36, 74)" }}>
                <div className='max-w-7xl md:my-20 my-10 mx-auto' id="faq">
                    <p className='text-center font-serif text-white font-black text-3xl md:text-5xl mb-10'>Back Tax FAQs</p>
                    {accordionData.map((item) => (
                        <div key={item.id} className="max-w-2xl mx-auto">
                            <AccordionItem item={item} />
                        </div>
                    ))}

                </div>
            </div>
            {/* Faq end */}








            {/* Services start */}
            <div>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div className='banner2'>

                    </div>
                    <div className='my-auto text-xl md:py-20 py-10 md:px-10 px-2 max-w-3xl lg:mx-w-2xl md:mx-auto lg:mr-auto'>
                        <p className='font-bold text-cyan-800 uppercase tracking-widest font-serif' id="learn-more">How To</p>
                        <h1 className='uppercase tracking-widest text-gray-800 text-2xl md:text-4xl font-black mb-10'>Get Tax debt relief ASAP</h1>
                        <p className='text-md max-w-md tracking-normal text-gray-700 mb-10'>If you need tax debt assistance with IRS tax relief, we’re here to help. We’ve got your back and can help with a variety of tax debt problems.</p>
                        <div className='mb-2 flex font-semibold text-gray-600 items-center'><strong className='text-cyan-500 text-2xl mr-2'><CheckIcon className='h-6 w-6' /></strong>Wage Garnishment</div>
                        <div className='mb-2 flex font-semibold text-gray-600 items-center'><strong className='text-cyan-500 text-2xl mr-2'><CheckIcon className='h-6 w-6' /></strong>Tax Negotiation</div>
                        <div className='mb-2 flex font-semibold text-gray-600 items-center'><strong className='text-cyan-500 text-2xl mr-2'><CheckIcon className='h-6 w-6' /></strong>Reduce IRS Tax Debt</div>
                        <div className='mb-2 flex font-semibold text-gray-600 items-center'><strong className='text-cyan-500 text-2xl mr-2'><CheckIcon className='h-6 w-6' /></strong>Resolve Back Taxes</div>
                        <p className='mt-10 md:mt-20 text-base md:text-lg text-center font-semibold italic text-gray-700'>Stop putting off your back taxes!</p>
                        <p className='my-5 text-center text-sm md:text-base font-normal text-gray-500 uppercase'>FREE Consultation</p>
                        <a href={`tel:${props.did}`}
                            id="fifthButtonConsultation"
                            className="flex items-center mx-auto justify-center mt-5 w-full md:w-fit rounded-sm drop-shadow-md hover:drop-shadow-2xl p-4 md:px-10 md:py-5 bg-sky-700 text-white hover:bg-gradient-to-r hover:from-teal-500 hover:to-sky-500 font-extrabold text-2xl uppercase tracking-widest">
                            {props.did}
                        </a>
                        <img
                            className="mt-5 mx-auto h-10 w-auto not-italic"
                            src="/ovation_logo_dark.png"
                            alt="Ovation"
                        />
                    </div>
                </div>
            </div>
            {/* Services end */}



            {/* Form start */}

            <div className='drop-shadow-2xl md:py-20 md:px-10 py-10 px-2' style={{ backgroundColor: "rgb(0, 36, 74)" }} id="consultation">
                <div className='w-fit mx-auto bg-white md:px-5 pt-10 pb-5 md:py-10 text-gray-500'>
                    <StepForm
                        dataArray={dataArray}
                        setDataArray={setDataArray}
                        updateFieldValue={updateFieldValue}
                        initialState={initialState}
                        source={props.source}
                        did={props.did}
                        setSuccess={setSuccess}
                    />
                </div>
            </div>

            {/* Form end */}

            {/* Call now start */}
            <div className='text-white md:py-20 md:px-10 py-10 px-2 group text-center banner' id="contact">
                <div className='my-auto'>
                    <h1 className='font-semibold text-white uppercase tracking-widest'>Have a question?</h1>
                    <h1 className='text-center font-serif text-white font-black text-3xl md:text-5xl mb-10'>Give us a call!</h1>
                    <p className='my-5 text-center text-sm md:text-base font-normal text-white uppercase'>No cost, no obligation</p>
                    <p className='italic text-white'>6am - 6pm Pacific, M-F</p>
                    <a href={`tel:${props.did}`}
                        id="sixthButtonPhone"
                        className="flex items-center mx-auto justify-center my-10 w-full md:w-fit rounded-sm drop-shadow-md hover:drop-shadow-2xl p-4 md:px-10 md:py-5 bg-white text-gray-900 hover:text-white hover:bg-gradient-to-r hover:from-teal-500 hover:to-sky-500 font-extrabold text-2xl uppercase tracking-widest">
                        {props.did}
                    </a>
                    <p className='italic'>
                        {online()}
                    </p>
                </div>
            </div>
            {/* Call now end */}



            <style>
                {
                    `
                    .peaks {
                        position:relative;
                        background-color:#E2EAF0;
                    }
                    
                    .peaks:after {
                        content:'';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -50px;
                        width: 0;
                        height: 0;
                        border-top: solid 50px #E2EAF0;
                        border-left: solid 50px transparent;
                        border-right: solid 50px transparent;
                    }
                    `
                }
            </style>
        </div>
    );
}
