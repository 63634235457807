import React, {useState} from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';

export default function AccordionItem(props) {
    const [isActiveTab, setIsActiveTab] = useState(false);
  return (
    <div>
      <div onClick={() => setIsActiveTab(!isActiveTab)} 
                className="px-4 py-5 bg-white space-y-6 sm:p-6 shadow
                            text-gray-500 
                            hover:bg-zinc-100
                            cursor-pointer 
                            p-5 
                            my-2
                            rounded-sm">
                <div className="flex justify-between">
                    <div className='font-semibold font-md text-gray-500'>
                        <span className='font-extrabold font-lg uppercase tracking-widest text-gray-900'>
                           {props.item.question}
                        </span>
                    </div>
                    <div className='text-gray-400 font-black'>
                        {isActiveTab ? <ChevronUpIcon className='h-5 w-5' /> :  <ChevronDownIcon className='h-5 w-5' />}
                    </div>
                </div>
            </div>
            {isActiveTab && 
                <div className='md:p-10 p-2 text-lg text-left'>
                    <p>{props.item.answer}</p>
                </div>
            }
    </div>
  );
}
